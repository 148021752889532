
.correlation-tracker-view {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    "back-to-homepage"
    "title"
    "infopoint"
    "task-list";
}

.correlation-tracker-view {
  max-width: 1000px;
}

.correlation-tracker-view__content {
  grid-area: task-list;
}

.correlation-tracker-view__title {
  grid-area: title;
  overflow: hidden;

  font-size: 1.25rem;
  text-overflow: ellipsis;

  &:hover {
    overflow: scroll;
    text-overflow: initial;
  }
}

.correlation-tracker-view__infopoint {
  margin-top: 2rem;
  margin-bottom: 1rem;
  grid-area: infopoint;
}
