.app-sidebar {
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: -241px;
  width: 240px;

  flex-direction: column;
  transition: left 0.5s;

  background-color: var(--sidebar-background-color, var(--background-color));
  color: var(--sidebar-text-color, var(--text-color));
}

.app-sidebar__backdrop-on-mobile {
  display: block;
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;

  background: rgba(14, 40, 59, 0.9);
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.app-sidebar--visible {
  left: 0;
}

.app-sidebar__backdrop-on-mobile--visible {
  right: 0;
  opacity: 1;
  pointer-events: all;
}

.backdrop-on-mobile__close-icon {
  position: absolute;
  right: 1rem;
  top: 2rem;

  color: white;
}

@media (min-width: 768px) {
  .app-sidebar {
    display: flex;
    left: 0;
  }

  .app__app-sidebar .app-sidebar  {
    display: flex;
    left: 0;
  }
}

.app-sidebar__top {
  flex: 1;
}

.app-sidebar__bottom {
  margin-bottom: 0.5rem;
}

.app-sidebar__header {
  margin: 5px 0 5px;
}

.app-sidebar__logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-direction: row;
  justify-content: center;
  min-height: 4rem;
  padding: 1rem;
  cursor: pointer;
}

.app-sidebar__logo-image {
  img {
    max-width: 100%;
    max-height: 64px;
  }
}

.app-sidebar__logo-title {
  flex: 1;
}

.app-sidebar__logo-title--no-logo {
  padding-left: 1rem;
}

.app-sidebar__main-nav {
  margin-top: 2rem;

  [class~='main-nav__nav-item']:last-of-type  {
    margin-bottom: 1rem;
}
}

.main-nav__nav-item {
  padding: 0 1rem;
  margin-top: 1rem;
  border-right: 2px solid var(--sidebar-item-border-color);
  font-size: 0.875rem;

  svg {
    margin-right: 1rem;
  }

  .nav-item {
    background: var(--sidebar-item-background-color);
    color: var(--sidebar-item-text-color);
  }
}

.main-nav__nav-item--active,
.main-nav__nav-item:hover {

  border-right-color: var(--sidebar-item-active-border-color);
  cursor: pointer;

  .nav-item {
    background: var(--sidebar-item-active-background-color);
    color: var(--sidebar-item-active-text-color);
  }
}

.nav-item {
  padding: 0.75rem 1rem;

  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
