.app-header {
  position: relative;
  display: flex;
  justify-content: space-between;

  padding: 1.5rem 1rem;
}

@media (min-width: 576px) {
  .app-header {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
}

.app-header__entry {
  display: flex;
  align-items: center;
}

.app-header__entry.app-header__entry--left {
  justify-content: flex-start;
}

.app-header__entry.app-header__entry--center {
  justify-content: center;
  flex: 1 100%;
}

.app-header__entry.app-header__entry--right {
  justify-content: flex-end;
  white-space: nowrap;
}

.app-header__title {
  display: inline-flex;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: inherit;
  font-size: 1.25rem;
  white-space: nowrap;
}

.app-header__menu-icon,
.app-header__help-icon {
  display: inline-flex;
  padding: 0.3125rem;
  margin-left: 0.1875rem;
  margin-right: 0.1875rem;
  line-height: inherit;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }

  svg {
    vertical-align: middle;
  }
}

.app-header__search-bar {
  width: 80%;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .app-header__menu-icon {
    display: none;
  }

  .app-header__search-bar {
    width: 60%;
  }
}

.search-bar {
  flex: 1;

  position: relative;
  width: 100%;
}

.search-bar__input {
  width: 100%;
  border: 1px solid var(--search-bar-input-border-color);
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(232,232,232,0.1);

  background-color: var(--search-bar-input-background-color, var(--background-color));
  color: var(--search-bar-input-text-color, var(--text-color));

  &:focus {
    background-color: var(--search-bar-input-focus-background-color, var(--background-color));
    color: var(--search-bar-input-focus-text-color, var(--text-color));

    border-color: var(--search-bar-input-focus-border-color);
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 215, 0.25);
  }
}

.search-bar__submit {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 50;

  color: var(--search-bar-submit-text-color, var(--text-color));
}
