.homepage-view {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content min-content;
  grid-template-areas:
    "infopoint"
    "startable-list"
    "task-list";

  padding-bottom: 1rem;
}

.homepage-view__infopoint {
  grid-area: infopoint;

  .alert {
    margin: 0.5rem 0 1.5rem 0;
  }
}

.homepage-view__startable-list {
  grid-area: startable-list;

  margin-bottom: 1.5rem;
}

.homepage-view__task-list {
  grid-area: task-list;

  margin-bottom: 1.5rem;
}
