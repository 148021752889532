.task-view {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.task-view__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  padding-top: 2rem;
  padding-bottom: 1rem;
}
